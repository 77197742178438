export const namespace = 'partsOrder/';

export const PartsOrderGetters = {
  GET_PARTS_ORDER: namespace + 'getPartsOrder',
  GET_DB_PARTS_ORDER: namespace + 'getdbPartsOrder',
  GET_PARTS_ORDER_SEARCH_RESULTS: namespace + 'getPartsOrderSearchResults',
  HAS_CHANGES: namespace + 'hasChanges'
};

export const PartsOrderActions = {
  FETCH_PARTS_ORDER: namespace + 'fetchPartsOrder',
  SEARCH_PARTS_ORDERS: namespace + 'searchPartsOrders',
  EXPORT_PARTS_ORDER_SEARCH_RESULTS: namespace + 'export'
};

export const PartsOrderMutations = {
  SET_PARTS_ORDER: namespace + 'setPartsOrder',
  SET_PARTS_ORDER_SEARCH_RESULTS: namespace + 'setPartsReqeustSearchResults'
};
