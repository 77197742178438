import axios from 'axios';

const PARTS_ORDER_PATH = '/api/parts-orders';
const SEARCH_PATH_ENDING = '/search';
const EXPORT_PATH = '/export';

class PartsOrderService {
  async fetchPartsOrder(partsOrderId) {
    const url = `${PARTS_ORDER_PATH}/${encodeURIComponent(partsOrderId)}`;
    const requestedParts = await axios.get(url);
    return requestedParts;
  }

  async searchPartsOrders(parameters) {
    const url = `${PARTS_ORDER_PATH}${SEARCH_PATH_ENDING}`;
    const result = await axios.get(url, {
      params: parameters
    });
    return result;
  }
  async exportPartsOrderSearchResults(parameters) {
    const url = `${PARTS_ORDER_PATH}${EXPORT_PATH}`;
    const result = await axios.post(url, parameters, {
      responseType: 'blob'
    });
    return result;
  }
}

export default new PartsOrderService(); // singleton object
